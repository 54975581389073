import {
  CacheConfig,
  Environment,
  Network,
  Observable,
  RecordSource,
  RequestParameters,
  Store,
  Variables,
} from "relay-runtime";

import { handleData, isMutation, isQuery } from "./helpers";
import fetchGraphQL from "fetchGraphQL";

/**
 * A Sink is an object of methods
 * provided by Observable during construction.
 * The methods are to be called to
 * trigger each event. It also contains a closed
 * field to see if the resulting
 * subscription has closed.
 */
export type Sink<T> = {
  next: (value: T) => void;
  error: (error: Error, isUncaughtThrownError?: boolean) => void;
  complete: () => void;
  closed: boolean;
};

// Define a function that fetches the results of an request (query/mutation/etc)
// and returns its results as a Promise:
export const fetchFunction = async (
  request: RequestParameters,
  variables: Variables,
  cacheConfig: CacheConfig,
  sink: Sink<any>
) => {
  try {
    const response = await fetchGraphQL(request, variables);
    const data = await handleData(response);

    if (data && data.errors && data.errors!.length > 0) {
      const err = data.errors[0];
      if (
        err?.message! === "TokenExpiredError" ||
        err?.message! === "JsonWebTokenError"
      ) {
        console.log(err);
        localStorage.removeItem("REACT_TOKEN_AUTH_KEY");
        localStorage.removeItem("user");
        window.location.reload();
        window.location.replace("/user/login");
        return;
      }
    }

    if (response.status === 401 || response.status === 422) {
      sink.error(data);
      sink.complete();

      // throw data.errors;
      return data.errors;
    }

    if (
      data &&
      data?.errors! &&
      data?.errors?.length! > 0 &&
      data.errors[0]?.extensions! &&
      data.errors[0]?.extensions?.exception!
    ) {
      sink.error(data);
      sink.complete();

      return data.errors;
    }

    if (isMutation(request) && data.errors) {
      sink.error(data);
      sink.complete();

      // throw data;
      return data;
    }

    if (!data.data) {
      sink.error(data);
      sink.complete();

      // throw data.errors;
      return data.errors;
    }

    // @TODO partial error handling
    // if entire response is empty and errors, this work
    if (isQuery(request) && data.errors && data.data && isEmpty(data.data)) {
      sink.error(data);
      sink.complete();

      return data;
    }

    sink.next(data);
    sink.complete();
    return data;
  } catch (err) {
    // eslint-disable-next-line
    console.log("err: ", err);

    const timeoutRegexp = new RegExp(/Still no successful response after/);
    const serverUnavailableRegexp = new RegExp(/Failed to fetch/);
    if (
      timeoutRegexp.test(err.message) ||
      serverUnavailableRegexp.test(err.message)
    ) {
      throw new Error("Unavailable service. Try again later.");
    }

    throw err;
  }
};

const isEmpty = (obj: Record<string, unknown>): boolean => {
  return Object.values(obj).every((element) => element === null);
  // return !Object.values(obj).some(element => element !== null);
  // return Object.entries(o).every(([k,v]) => v === null);
};

const fetchQuery = (
  request: RequestParameters,
  variables: Variables,
  cacheConfig: CacheConfig
) => {
  return Observable.create((sink) => {
    fetchFunction(request, variables, cacheConfig, sink);
  });
};

const network = Network.create(fetchQuery);

const env = new Environment({
  network: network,
  store: new Store(new RecordSource(), {
    // This property tells Relay to not immediately clear its cache when the user
    // navigates around the app. Relay will hold onto the specified number of
    // query results, allowing the user to return to recently visited pages
    // and reusing cached data if its available/fresh.
    gcReleaseBufferSize: 10,
  }),
});

export default env;
