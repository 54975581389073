import { lazy } from "react";
import graphql from "babel-plugin-relay/macro";
// import RedirectException from "found/RedirectException";
//import HttpError from "found/HttpError";

import Layout from "common/Layouts/Admin";
import { isLoggedin } from "common/hooks/useAuth";
import RedirectNonAdmin, { loginUrl } from "common/RedirectNonAdmin";

const routes = [
  {
    path: "/admin/audit",
    query: graphql`
      query AuditAdminQuery {
        ...ListAdmin_audits
      }
    `,
    fetchPolicy: "store-and-network",
    getComponent: () =>
      lazy(() => import(/* webpackChunkName: 'admin' */ "./List")),
    render: ({ props, Component, error, resolving }) => {
      if (resolving && !isLoggedin()) {
        // throw new RedirectException(`/user/login`);
        window.location.href = loginUrl;
      }

      RedirectNonAdmin();

      return (
        <Layout data={props} error={error ? error : null}>
          <Component data={props} error={error ? error : null} />
        </Layout>
      );
    },
  },
  {
    path: "/admin/audit/view/:id",
    query: graphql`
      query AuditAdminViewQuery($id: ID!) {
        audit(id: $id) {
          ...ViewAdminAudit_data
        }
      }
    `,
    getComponent: () =>
      lazy(() => import(/* webpackChunkName: 'admin' */ "./View")),
    prepareVariables: (params) => ({ id: params.id || 0 }),
    render: ({ props, Component, error, resolving }) => {
      if (resolving && !isLoggedin()) {
        // throw new RedirectException(`/user/login`);
        window.location.href = loginUrl;
      }

      RedirectNonAdmin();

      // if (error) {
      //   throw new HttpError(404, error);
      // }

      return (
        <Layout data={props} error={error ? error : null}>
          {props && (
            <Component
              data={props ? props.audit : null}
              error={error ? error : null}
            />
          )}
        </Layout>
      );
    },
  },
];

export default routes;
