import { RequestParameters, Variables } from "relay-runtime";
import fetchWithRetries from "common/Relay/fetchWithRetries";
import { getRequestBody } from "common/Relay/helpers";

const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";
const url = process.env.REACT_APP_API_DOMAIN || "https://hfa.gleez.co";
const GRAPHQL_URL = `${url}/graphql`;

// your-app-name/src/fetchGraphQL.js
const fetchGraphQL = async (
  request: RequestParameters,
  variables: Variables
) => {
  const headers: HeadersInit = new Headers();
  const token = localStorage.getItem("REACT_TOKEN_AUTH_KEY");

  headers.set("Accept", "application/json");
  headers.set("Content-Type", "application/json");
  headers.set("authorization", `Bearer ${token}`);

  if (isDev) {
    headers.set("X-WEBPACK_DEV_SERVER", "true");
    console.log(
      `fetching query ${request.name} with ${JSON.stringify(variables)}`
    );
  }

  const body = getRequestBody(request, variables);

  // Fetch data from GraphQL API:
  const response = await fetchWithRetries(GRAPHQL_URL, {
    method: "POST",
    headers: headers,
    body: body,
    fetchTimeout: 20000,
    credentials: "include",
    retryDelays: [1000, 3000, 10000],
  });

  return response;
};

export default fetchGraphQL;
