import audit from "./Audit";
import authLog from "./AuthLog";
import company from "./Company";
import dashboard from "./Dashboard";
import user from "./User";
import publication from "./Publication";

const routes = [
  ...audit,
  ...authLog,
  ...company,
  ...dashboard,
  ...user,
  ...publication,
];

export default routes;
