import * as React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import Spinner from "common/SpinnerFacebook";
import TopBar from "./TopBar";
// import NavBar from "./NavBar";
import CookieConsentComponent from "common/CookieConsentComponent";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      backgroundImage:
        "linear-gradient(180deg,#f9fafa 0,#f9fafa 514px,#fff 514px,#fff 100%)",
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  })
);

interface IProps {
  data?: any | null;
  error?: any | null;
  children: React.ReactNode;
}

export default function AgentLayout(props: IProps) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [, setMobileNavOpen] = React.useState(false);

  return (
    <div className={classes.root}>
      <TopBar
        onMobileNavOpen={() => setMobileNavOpen(true)}
        setOpen={setOpen}
        isOpen={open}
        match={props?.data?.match}
      />
      {/* <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        setOpen={setOpen}
        isOpen={open}
      /> */}

      <main className={classes.content}>
        <div className={classes.toolbar} />
        <React.Suspense fallback={<Spinner />}>{props.children}</React.Suspense>
      </main>

      <CookieConsentComponent />
    </div>
  );
}
