import React, { useEffect } from "react";
import clsx from "clsx";
import useRouter from "found/useRouter";
// import RouterLink from "found/Link";
import {
  createStyles,
  makeStyles,
  useTheme,
  Theme,
} from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";

import AnnouncementIcon from "@material-ui/icons/Announcement";
import BarChartIcon from "@material-ui/icons/BarChart";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import DomainIcon from "@material-ui/icons/Domain";
// //import FavoriteIcon from '@material-ui/icons/Favorite';
// //import HospitalIcon from '@material-ui/icons/LocalHospital';
// import LabelIcon from "@material-ui/icons/LabelImportant";
// import LockIcon from '@material-ui/icons/EnhancedEncryption';
//import ScheduleIcon from '@material-ui/icons/Schedule';
import DescriptionIcon from "@material-ui/icons/Description";
// import SettingsIcon from "@material-ui/icons/Settings";
// import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import UsersIcon from "@material-ui/icons/People";
// import WhatsAppIcon from "@material-ui/icons/WhatsApp";
// import PaymentIcon from "@material-ui/icons/Payment";
import StorageIcon from "@material-ui/icons/Storage";
import BookIcon from "@material-ui/icons/Book";
//import PersonAddIcon from "@material-ui/icons/PersonAdd";
// import ImportContactsIcon from "@material-ui/icons/ImportContacts";

import { user as cuser } from "common/hooks/useAuth";
import NavItem from "./NavItem";

const drawerWidth = 240;

const user = {
  avatar: "/static/images/avatars/avatar_6.png",
  jobTitle: "Super Admin",
  name: cuser()?.firstName! + " " + cuser()?.lastName!,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    name: {},
    mobileDrawer: {
      width: drawerWidth,
    },
    desktopDrawer: {
      width: drawerWidth,
      // top: 64,
      // height: 'calc(100% - 64px)',
    },
    avatar: {
      cursor: "pointer",
      width: 64,
      height: 64,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1,
      },
    },
    hide: {
      display: "none",
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
  })
);

const items = [
  {
    href: "/admin/dashboard",
    icon: BarChartIcon,
    title: "Dashboard",
  },
  {
    href: "/admin/companies",
    icon: DomainIcon,
    title: "Accounts",
  },
  {
    href: "/admin/users",
    icon: UsersIcon,
    title: "Users",
  },
];

const postItems = [
  {
    href: "/admin/publications",
    icon: DescriptionIcon,
    title: "Publications",
  },
  {
    href: "/admin/emails",
    icon: AnnouncementIcon,
    title: "NewsLetter",
  },
];

const docmeItems = [
  {
    href: "/admin/audit",
    icon: StorageIcon,
    title: "Audit Log",
  },
  {
    href: "/admin/auth",
    icon: BookIcon,
    title: "Auth Log",
  },
];

interface IProps {
  onMobileClose: () => void;
  openMobile: boolean;
  isOpen: boolean;
  setOpen: any;
}

const NavBar = ({ onMobileClose, openMobile, isOpen, setOpen }: IProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const { match } = useRouter();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match!.location]);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box
        className={clsx({
          [classes.hide]: !isOpen,
        })}
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
      >
        <Avatar className={classes.avatar} src={user.avatar} />
        <Typography className={classes.name} color="textPrimary" variant="h5">
          {user.name}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {user.jobTitle}
        </Typography>
      </Box>

      <Divider />

      <Box py={0}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>

        <Divider />

        <List>
          {postItems.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>

        <Divider />

        <List>
          {docmeItems.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>

      <Hidden mdDown>
        <Drawer
          anchor="left"
          // classes={{ paper: classes.desktopDrawer }}
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: isOpen,
            [classes.drawerClose]: !isOpen,
          })}
          classes={{
            paper: clsx(classes.desktopDrawer, {
              [classes.drawerOpen]: isOpen,
              [classes.drawerClose]: !isOpen,
            }),
          }}
          open
          // variant="persistent"
          variant="permanent"
        >
          <div className={classes.toolbar}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </div>

          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

export default NavBar;
