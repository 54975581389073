/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DashboardLoadingAdminQueryVariables = {};
export type DashboardLoadingAdminQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"Loading_data">;
};
export type DashboardLoadingAdminQuery = {
    readonly response: DashboardLoadingAdminQueryResponse;
    readonly variables: DashboardLoadingAdminQueryVariables;
};



/*
query DashboardLoadingAdminQuery {
  ...Loading_data
}

fragment Loading_data on Query {
  me {
    id
    firstName
    lastName
    role
  }
}
*/

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DashboardLoadingAdminQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "Loading_data"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DashboardLoadingAdminQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "role",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b37d059918880e8594fab75c8c4c0cc8",
    "id": null,
    "metadata": {},
    "name": "DashboardLoadingAdminQuery",
    "operationKind": "query",
    "text": "query DashboardLoadingAdminQuery {\n  ...Loading_data\n}\n\nfragment Loading_data on Query {\n  me {\n    id\n    firstName\n    lastName\n    role\n  }\n}\n"
  }
};
(node as any).hash = 'cf0e646b3a9e3439dbac93f119307d08';
export default node;
