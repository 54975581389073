/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CompanyAdminEditQueryVariables = {
    id: string;
};
export type CompanyAdminEditQueryResponse = {
    readonly company: {
        readonly " $fragmentRefs": FragmentRefs<"EditAdminCompany_data">;
    };
};
export type CompanyAdminEditQuery = {
    readonly response: CompanyAdminEditQueryResponse;
    readonly variables: CompanyAdminEditQueryVariables;
};



/*
query CompanyAdminEditQuery(
  $id: ID!
) {
  company(id: $id) {
    ...EditAdminCompany_data
    id
  }
}

fragment EditAdminCompany_data on Company {
  id
  name
  type
  status
  phone
  altPhone
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CompanyAdminEditQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Company",
        "kind": "LinkedField",
        "name": "company",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EditAdminCompany_data"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CompanyAdminEditQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Company",
        "kind": "LinkedField",
        "name": "company",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "phone",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "altPhone",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "93c4c22e33f36a7e627ef98c23c4ca5d",
    "id": null,
    "metadata": {},
    "name": "CompanyAdminEditQuery",
    "operationKind": "query",
    "text": "query CompanyAdminEditQuery(\n  $id: ID!\n) {\n  company(id: $id) {\n    ...EditAdminCompany_data\n    id\n  }\n}\n\nfragment EditAdminCompany_data on Company {\n  id\n  name\n  type\n  status\n  phone\n  altPhone\n}\n"
  }
};
})();
(node as any).hash = '58f0e8008903b6f7e56115c0c05a00aa';
export default node;
