import { lazy } from "react";
import graphql from "babel-plugin-relay/macro";
import RedirectException from "found/RedirectException";
// import HttpError from "found/HttpError";

import Layout from "common/Layouts/Default";
import { isLoggedin } from "common/hooks/useAuth";

const routes = [
  {
    path: "/",
    getComponent: () =>
      lazy(() => import(/* webpackChunkName: 'user' */ "./Welcome")),
    render: ({ Component, resolving, props }) => {
      //   if (resolving && !isLoggedin()) {
      //     throw new RedirectException(`/user/login`);
      //   }

      //   if (!resolving && isLoggedin()) {
      //     throw new RedirectException(`/loading`);
      //   }

      return (
        <Layout data={props}>
          <Component data={props} />
        </Layout>
      );
    },
  },
  {
    path: "/contact",
    getComponent: () =>
      lazy(() => import(/* webpackChunkName: 'user' */ "./Contact")),
    render: ({ Component, resolving, props }) => {
      return (
        <Layout data={props}>
          <Component data={props} />
        </Layout>
      );
    },
  },
  {
    path: "/terms-conditions",
    getComponent: () =>
      lazy(() => import(/* webpackChunkName: 'user' */ "./Terms")),
    render: ({ Component, resolving, props }) => {
      return (
        <Layout data={props}>
          <Component data={props} />
        </Layout>
      );
    },
  },
  {
    path: "/user/signup",
    getComponent: () =>
      lazy(() => import(/* webpackChunkName: 'user' */ "./Signup")),
    render: ({ Component, resolving, props }) => {
      if (resolving && isLoggedin()) {
        throw new RedirectException(`/publications`);
      }

      return (
        <Layout data={props} hideNavbar={true}>
          <Component data={props} />
        </Layout>
      );
    },
  },
  {
    path: "/user/login",
    getComponent: () =>
      lazy(() => import(/* webpackChunkName: 'user' */ "./Login")),
    render: ({ Component, resolving, props }) => {
      if (resolving && isLoggedin()) {
        // window.location.reload();
        throw new RedirectException(`/publications`);
      }

      return (
        <Layout
          data={props}
          hideNavbar={true}
          hideFooter={true}
          centerLayout={true}
        >
          <Component data={props} />
        </Layout>
      );
    },
  },
  {
    path: "/user/password/forgot",
    getComponent: () =>
      lazy(() => import(/* webpackChunkName: 'user' */ "./ForgotPassword")),
    render: ({ Component, resolving, props }) => {
      if (resolving && isLoggedin()) {
        throw new RedirectException(`/publications`);
      }

      return (
        <Layout
          data={props}
          hideNavbar={true}
          hideFooter={true}
          centerLayout={true}
        >
          <Component data={props} />
        </Layout>
      );
    },
  },
  {
    path: "/user/password/change/:id",
    query: graphql`
      query userChangePasswordQuery($id: ID!) {
        user(id: $id) {
          ...ChangePasswordUser_data
        }
      }
    `,
    getComponent: () =>
      lazy(() => import(/* webpackChunkName: 'user' */ "./ChangePassword")),
    prepareVariables: (params) => ({ id: params.id || 0 }),
    render: ({ props, Component, error, resolving }) => {
      if (resolving && !isLoggedin()) {
        throw new RedirectException(`/user/login`);
      }

      // if (error) {
      //   throw new HttpError(404, error);
      // }

      return (
        <Layout
          data={props}
          error={error ? error : null}
          hideNavbar={true}
          hideFooter={true}
          centerLayout={true}
        >
          {props && (
            <Component
              data={props ? props.user : null}
              error={error ? error : null}
            />
          )}
        </Layout>
      );
    },
  },
  {
    path: "/user/verify/:token",
    query: graphql`
      query userResetPasswordQuery($token: String!) {
        verifyToken(token: $token) {
          ...ResetPasswordUser_data
        }
      }
    `,
    getComponent: () =>
      lazy(() => import(/* webpackChunkName: 'user' */ "./ResetPassword")),
    prepareVariables: (params, { location }) => ({
      token: params.token || "",
      mytoken: location.query.mytoken || "",
    }),
    render: ({ props, Component, error, resolving }) => {
      if (resolving && isLoggedin()) {
        throw new RedirectException(`/publications`);
      }

      return (
        <Layout
          data={props}
          error={error ? error : null}
          hideNavbar={true}
          hideFooter={true}
          centerLayout={true}
        >
          <Component
            data={props ? props.verifyToken : null}
            error={error ? error : null}
          />
        </Layout>
      );
    },
  },
  {
    path: "/user/forgotPassword/message",
    getComponent: () =>
      lazy(() => import(/* webpackChunkName: 'user' */ "./LinkMessage")),
    render: ({ props, Component, error, resolving }) => {
      return (
        <Layout
          data={props}
          error={error ? error : null}
          hideNavbar={true}
          hideFooter={true}
          centerLayout={true}
        >
          {props && (
            <Component
              data={props ? props.verifyToken : null}
              error={error ? error : null}
            />
          )}
        </Layout>
      );
    },
  },
  {
    path: "/user/createPassword/message",
    getComponent: () =>
      lazy(() =>
        import(/* webpackChunkName: 'user' */ "./CreatePasswordMessage")
      ),
    render: ({ props, Component, error, resolving }) => {
      return (
        <Layout
          data={props}
          error={error ? error : null}
          hideNavbar={true}
          hideFooter={true}
          centerLayout={true}
        >
          {props && (
            <Component
              data={props ? props.verifyToken : null}
              error={error ? error : null}
            />
          )}
        </Layout>
      );
    },
  },
  {
    path: "/user/signup/message",
    getComponent: () =>
      lazy(() => import(/* webpackChunkName: 'user' */ "./SignupMessage")),
    render: ({ props, Component, error, resolving }) => {
      return (
        <Layout
          data={props}
          error={error ? error : null}
          hideNavbar={true}
          hideFooter={true}
          centerLayout={true}
        >
          <Component
            data={props ? props.verifyToken : null}
            error={error ? error : null}
          />
        </Layout>
      );
    },
  },
];

export default routes;
