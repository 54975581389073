import React from "react";
import CookieConsent from "react-cookie-consent";
import Container from "@material-ui/core/Container";

const CookieConsentComponent = () => {
  return (
    <CookieConsent
      debug={false}
      location="top"
      buttonText="ACCEPT"
      cookieName="hfaCookie"
      enableDeclineButton
      declineButtonText="DECLINE"
      buttonWrapperClasses="MuiContainer-root MuiContainer-maxWidthLg cs-cookie-wrap"
      style={{
        background: "#ffffff",
        color: "#33475b",
        display: "block",
        textAlign: "right",
        padding: "20px",
      }}
      buttonStyle={{
        borderRadius: "3px",
        padding: "10px 16px",
        color: "#fff",
        fontSize: "13px",
        background: "#a0228e",
        border: "1px solid #a0228e",
      }}
      declineButtonStyle={{
        borderRadius: "3px",
        padding: "10px 16px",
        color: "#a0228e",
        fontSize: "13px",
        background: "#fff",
        border: "1px solid #a0228e",
      }}
      expires={150}
    >
      <Container style={{ textAlign: "left", fontSize: "12px" }}>
        This website stores cookies on your computer. These cookies are used to
        collect information about how you interact with our website and allow us
        to remember you. We use this information in order to improve and
        customize your browsing experience and for analytics and metrics about
        our visitors both on this website and other media. To find out more
        about the cookies we use, see our &nbsp;
        <a
          style={{ color: "#a0228e" }}
          target="_blank"
          href="https://www.pageantmedia.com/privacy/"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
        &nbsp; and &nbsp;
        <a
          style={{ color: "#a0228e" }}
          target="_blank"
          href="https://www.pageantmedia.com/cookies/"
          rel="noreferrer"
        >
          Cookie Policy
        </a>
        .
        <br />
        <br />
        If you decline, your information won't be tracked when you visit this
        website. A single cookie will be used in your browser to remember your
        preference not to be tracked.{" "}
      </Container>
    </CookieConsent>
  );
};

export default CookieConsentComponent;
