import React, { useState } from "react";
import Link from "found/Link";
import useRouter from "found/useRouter";
import clsx from "clsx";

import AppBar from "@material-ui/core/AppBar";
// import Badge from "@material-ui/core/Badge";
import Box from "@material-ui/core/Box";
// import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneForwardedIcon from "@material-ui/icons/PhoneForwarded";
import MaterialLink from "@material-ui/core/Link";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";
// import MenuIcon from "@material-ui/icons/Menu";
// import NotificationsIcon from "@material-ui/icons/NotificationsOutlined";
import InputIcon from "@material-ui/icons/Input";
// import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import whitelogo from "common/images/hfa-logo-white.png";

import useAuth from "common/hooks/useAuth";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    avatar: {
      width: 60,
      height: 60,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: "none",
    },
    title: {
      flexGrow: 1,
      fontWeight: 600,
      color: "#fff",
      // fontSize: '2.2243rem',
      // marginRight: theme.spacing(1),
      // marginLeft: theme.spacing(1),
    },
    logo: {
      width: "235px",
      [theme.breakpoints.down("sm")]: {
        width: "88px",
      },
    },
    backButtonText: {
      marginLeft: theme.spacing(1),
    },
    contactBox: {
      width: "215px",
      [theme.breakpoints.down("sm")]: {
        width: "180px",
      },
    },
    contactBoxTitle: {
      fontWeight: 600,
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.7rem",
      },
    },
    contactBoxItem: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.7rem",
      },
    },
    contactBoxLink: {
      color: theme.palette.primary.contrastText,
    },
  })
);

interface IProps {
  data?: any | null;
  error?: any | null;
  match?: any | null;
  className?: string;
  isOpen: boolean;
  onMobileNavOpen: () => void;
  setOpen: any;
}

const TopBar = ({
  className,
  onMobileNavOpen,
  isOpen,
  setOpen,
  match,
  ...rest
}: IProps) => {
  const classes = useStyles();
  const { router } = useRouter();
  const { isLoggedin, logout, getCurrentUser } = useAuth();
  //   const [notifications] = useState([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  //   const handleDrawerOpen = () => {
  //     setOpen(true);
  //   };

  const onLogIn = (event: any) => {
    if (isLoggedin) {
      event.preventDefault();

      logout();
      // window.location.reload();
    }

    router.push("/");
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  //   const isRouterPublication = match?.location?.pathname === "/publications";

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: isOpen,
      })}
      elevation={0}
      {...rest}
    >
      <Toolbar>
        {/* <Hidden mdDown>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: isOpen,
            })}
          >
            <MenuIcon />
          </IconButton>
        </Hidden> */}

        <img src={whitelogo} alt="logo" className={classes.logo} />
        {/* {isRouterPublication === false && (
          <IconButton
            edge="end"
            aria-label="back to publications"
            aria-haspopup="true"
            color="inherit"
            to="/publications"
            component={Link}
          >
            <KeyboardBackspaceIcon />

            <Typography
              className={classes.backButtonText}
              noWrap
              component="span"
            >
              Back to Publication List
            </Typography>
          </IconButton>
        )} */}

        <Box flexGrow={1} />

        <Grid container direction="column" className={classes.contactBox}>
          <Typography variant="body2" className={classes.contactBoxTitle}>
            Contact us:
          </Typography>

          <Typography variant="body2" className={classes.contactBoxItem}>
            <MailOutlineIcon
              style={{ fontSize: "1rem", marginRight: "0.25rem" }}
            />

            <MaterialLink
              className={classes.contactBoxLink}
              href="mailto:support@hedgefundalert.com"
            >
              support@hedgefundalert.com
            </MaterialLink>
          </Typography>

          <Typography variant="body2" className={classes.contactBoxItem}>
            <PhoneForwardedIcon
              style={{ fontSize: "1rem", marginRight: "0.25rem" }}
            />
            <MaterialLink
              className={classes.contactBoxLink}
              href="tel:+646 891 2126"
            >
              +646 891 2126
            </MaterialLink>
          </Typography>
        </Grid>

        <>
          <IconButton
            edge="end"
            aria-label="account of current user"
            // aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit"
            // href={`/users/edit/${getCurrentUser()?.id!}`}
          >
            <AccountCircleIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              onClick={handleClose}
              component={Link}
              to={`/users/edit/${getCurrentUser()?.id}`}
            >
              {" "}
              My account
            </MenuItem>
            <MenuItem
              onClick={handleClose}
              component={Link}
              to={`/users/password/change/${getCurrentUser()?.id}`}
            >
              {" "}
              Change Password
            </MenuItem>
            <MenuItem onClick={onLogIn}>
              {" "}
              <InputIcon /> Logout
            </MenuItem>
          </Menu>
        </>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
