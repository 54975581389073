import * as React from "react";
import { createDefaultStore } from "util/defaultStore";

export interface IAuthContext {
  getToken: () => any;
  isLoggedIn: () => boolean;
  setToken: (token: string | null) => void;
  subscribe: (listener: (logged: boolean) => void) => void;
  unsubscribe: (listener: (logged: boolean) => void) => void;
}

export const AuthContext = React.createContext<IAuthContext>(
  {} as IAuthContext
);

export interface IAuthProviderConfig {
  accessTokenExpireKey?: string;
  accessTokenKey?: string;
  localStorageKey?: string;
  onUpdateToken?: (token: string) => Promise<string | null>;
  useCookie?: boolean;
  storage?: {
    getItem: (key: string) => any;
    setItem: (key: string, value: any) => void;
    removeItem: (key: string) => void;
  };
  children: React.ReactNode;
}

export const AuthProvider = ({
  children,
  accessTokenExpireKey,
  accessTokenKey = "accessToken",
  localStorageKey = "REACT_TOKEN_AUTH_KEY",
  onUpdateToken,
  useCookie,
  storage = createDefaultStore({
    [localStorageKey]: localStorage.getItem(localStorageKey),
  }),
}: IAuthProviderConfig) => {
  const tp = createTokenProvider({
    accessTokenExpireKey,
    accessTokenKey,
    localStorageKey,
    onUpdateToken,
    storage,
    useCookie,
  });

  return <AuthContext.Provider value={tp}>{children}</AuthContext.Provider>;
};

interface ITokenProviderConfig {
  accessTokenExpireKey?: string;
  accessTokenKey?: string;
  localStorageKey: string;
  onUpdateToken?: (token: string) => Promise<string | null>;
  useCookie?: boolean;
  storage: {
    getItem: (key: string) => any;
    setItem: (key: string, value: any) => void;
    removeItem: (key: string) => void;
  };
}

const createTokenProvider = ({
  localStorageKey,
  accessTokenExpireKey,
  onUpdateToken,
  storage,
}: ITokenProviderConfig) => {
  let listeners: Array<(newLogged: boolean) => void> = [];

  const getTokenInternal = (): any => {
    const data = storage.getItem(localStorageKey);

    return data;
    // try {
    //   const token = (data && JSON.parse(atob(data))) || null;
    //   return token;
    // } catch (e) {
    //   console.log(e)
    //   return null;
    // }
  };

  const subscribe = (listener: (logged: boolean) => void) => {
    listeners.push(listener);
  };

  const unsubscribe = (listener: (logged: boolean) => void) => {
    listeners = listeners.filter((l) => l !== listener);
  };

  const jwtExp = (token?: any): number | null => {
    if (!(typeof token === "string")) {
      return null;
    }

    const split = token.split(".");

    if (split.length < 2) {
      return null;
    }

    try {
      const jwt = JSON.parse(atob(token.split(".")[1]));
      if (jwt && jwt.exp && Number.isFinite(jwt.exp)) {
        return jwt.exp * 1000;
      } else {
        return null;
      }
    } catch (e) {
      return null;
    }
  };

  const getExpire = (token: string | null) => {
    if (!token) {
      return null;
    }

    if (accessTokenExpireKey) {
      // @ts-ignore
      return token[accessTokenExpireKey];
    }

    // if (accessTokenKey) {
    //   // @ts-ignore
    //   const exp = jwtExp(token[accessTokenKey]);
    //   if (exp) {
    //     return exp;
    //   }
    // }

    return jwtExp(token);
  };

  const isExpired = (exp?: number) => {
    if (!exp) {
      return false;
    }

    return Date.now() > exp;
  };

  const checkExpiry = async () => {
    const token = getTokenInternal();

    if (token && isExpired(getExpire(token))) {
      const newToken = onUpdateToken ? await onUpdateToken(token) : null;

      if (newToken) {
        setToken(newToken);
      } else {
        storage.removeItem(localStorageKey);
      }
    }
  };

  const getToken = async () => {
    await checkExpiry();

    // const token = getTokenInternal();
    // if (accessTokenKey) {
    //   // @ts-ignore
    //   return token && token[accessTokenKey];
    // }

    return getTokenInternal();
  };

  const isLoggedIn = () => {
    return !!getTokenInternal();
  };

  const setToken = (token: string | null) => {
    if (token) {
      storage.setItem(localStorageKey, token);
    } else {
      storage.removeItem(localStorageKey);
    }
    notify();
  };

  const notify = () => {
    const isLogged = isLoggedIn();
    listeners.forEach((l) => l(isLogged));
  };

  return {
    getToken,
    isLoggedIn,
    setToken,
    subscribe,
    unsubscribe,
  };
};
