import { Fragment, Suspense } from "react";
import cn from "clsx";
import PropTypes from "prop-types";
// import useRouter from 'found/useRouter';
//import HttpError from 'found/HttpError';

// import graphql from 'babel-plugin-relay/macro';
// import { useFragment } from 'relay-hooks';

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";

import NavBar from "./LayoutHeader";
import Footer from "./LayoutFooter";
import Spinner from "common/SpinnerFacebook";
import CookieConsentComponent from "common/CookieConsentComponent";
// import useAuth from 'common/hooks/useAuth';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      layout: {
        width: "auto",
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(1),
        [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
          width: 1100,
          marginLeft: "auto",
          marginRight: "auto",
        },
      },
      customLayout: {
        width: "auto",
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(1100 + theme.spacing(6))]: {
          //width: 1100,
          marginLeft: "auto",
          marginRight: "auto",
        },
      },
      cardGrid: {
        //padding: `${theme.spacing(1) * 3}px 0`,
      },
      main: ({ centerLayout }) => {
        const mainStyle = {
          /* flex: 1; would be enough but IE has different defaults (surprise!) */
          flex: "1 0 auto",
        };

        if (Boolean(centerLayout)) {
          mainStyle.backgroundImage = undefined;
          mainStyle.height = "100vh";
          mainStyle.minHeight = "500px";
          mainStyle.display = "flex";
          mainStyle.alignItems = "center";
          mainStyle.justifyContent = "center";
        }

        return mainStyle;
      },
      "@global #root": {
        display: "flex",
        minHeight: "100vh",
        flexDirection: "column",
      },
    }),
  { name: "Layout" }
);

// const fragmentSpec = graphql`
//   fragment Layout_data on Query {
//     me {
//       id
//       fullName
//       image
//     }
//   }
// `;

const Layout = (props) => {
  const { custom, appBarMargin, hideNavbar, hideFooter, centerLayout } = props;
  const classes = useStyles({ centerLayout });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  //const matomo = useContext(MatomoContext);
  // const { match } = useRouter();
  //const { isLoggedin } = useAuth();
  //const me = useFragment(fragmentSpec, props.data);

  const className = custom
    ? classes.customLayout
    : cn(classes.layout, classes.cardGrid);
  //const hero = heroUnit ? <HeroUnit isLoggedin={isLoggedin} /> : null;

  return (
    <Fragment>
      {!hideNavbar && (
        <NavBar
          //isLoggedin={isLoggedin}
          // heroUnit={heroUnit}
          appBarMargin={appBarMargin}
          isMobile={isMobile}
        />
      )}

      <div className={classes.main}>
        {/* {hero} */}
        <Suspense fallback={<Spinner />}>
          <div className={className}>{props.children}</div>
        </Suspense>
      </div>

      {!hideFooter && <Footer />}

      <CookieConsentComponent />
    </Fragment>
  );
};

Layout.propTypes = {
  heroUnit: PropTypes.bool,
  custom: PropTypes.bool,
  children: PropTypes.node.isRequired,
  appBarMargin: PropTypes.bool,
  hideNavbar: PropTypes.bool,
  hideFooter: PropTypes.bool,
  centerLayout: PropTypes.bool,
};

Layout.defaultProps = {
  heroUnit: false,
  custom: false,
  appBarMargin: false,
  hideNavbar: false,
  hideFooter: false,
  centerLayout: false,
};

export default Layout;
