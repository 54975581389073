import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
// import indigo from "@material-ui/core/colors/indigo";
import blueGrey from "@material-ui/core/colors/blueGrey";

// A theme with custom primary and secondary color.
// It's optional.
export default createMuiTheme({
  palette: {
    // background: {
    //   default: '#ffffff',
    //   paper: '#ffffff',
    // },
    primary: {
      light: "#d458bf",
      main: "#a0228e",
      dark: "#6e0060",
    },
    // secondary: {
    //   light: indigo[300],
    //   main: indigo[500],
    //   dark: indigo[700],
    // },
    text: {
      primary: blueGrey[900],
      secondary: blueGrey[600],
    },
    warning: {
      main: "#ffc071",
      dark: "#ffb25e",
    },
    error: {
      // xLight: red[50],
      main: red[500],
      dark: red[700],
    },
    success: {
      // xLight: green[50],
      dark: green[700],
      main: green[500],
    },
  },
  typography: {
    fontFamily: ["Raleway", "Source Sans Pro", "sans-serif"].join(","),

    h1: {
      fontWeight: 700,
      fontSize: "2.25rem",
    },
    h2: {
      fontWeight: 700,
      fontSize: "1.3125rem",
    },
    h3: {
      fontWeight: 700,
      fontSize: "0.875rem",
      textTransform: "uppercase",
    },
  },
});
