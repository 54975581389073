import admin from "./admin";
import authenticated from "./authenticated";
import userRoutes from "./user";

const targetBuild = process.env.REACT_APP_TARGET_BUILD;

let routes: any = [...userRoutes, ...authenticated, ...admin];
const indexLoginPage = 4;

switch (targetBuild) {
  case "admin":
    // Build admin-only
    routes = [...admin, { ...userRoutes[indexLoginPage] }];
    break;
  case "public":
    // Build public-only
    routes = [...userRoutes, ...authenticated];
    break;
  default:
  //
}

export default routes;
