import { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext } from "./AuthProvider";

export default function useAuth() {
  const tp = useContext(AuthContext);
  const [isLoggedin, setIsLogged] = useState(tp.isLoggedIn());

  const listener = useCallback(
    (newIsLogged: boolean) => {
      setIsLogged(newIsLogged);
    },
    [setIsLogged]
  );

  useEffect(() => {
    tp.subscribe(listener);

    return () => {
      tp.unsubscribe(listener);
    };
  }, [listener, tp]);

  const login = (newToken: string | null) => {
    tp.setToken(newToken);
    // window.location.reload();
  };

  const logout = () => {
    tp.setToken(null);
    localStorage.removeItem("user");
    // window.location.reload();
  };

  const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user")!);
  };

  return {
    getCurrentUser,
    // isLoggedin: tp.isLoggedIn(),
    isLoggedin,
    login,
    logout,
    user: getCurrentUser(),
  };
}

export const isLoggedin = (): Boolean => {
  return !!localStorage.getItem("REACT_TOKEN_AUTH_KEY");
};

export const user = (): Record<string, any> => {
  return JSON.parse(localStorage.getItem("user")!);
};

export const logOut = (): void => {
  localStorage.removeItem("REACT_TOKEN_AUTH_KEY");
  localStorage.removeItem("user");
  // window.location.reload();
};
